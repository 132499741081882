<script setup>

</script>

<template>
  <section class="container terms-container">
    <p>Your privacy is of paramount importance to us. In fact, we know that you care how information
      about you is used and shared, and we appreciate your trust that we will do so carefully and
      sensibly. We do not provide your personal information (such as your name, email, phone number,
      address, or credit card information) to other companies, except as required to transact business
      on your behalf: with the airlines to book your flights and purchase tickets, or with credit card
      companies to redeem your miles or points.
      </p>
    <p>Please read this Privacy Policy carefully for full details.</p>
    <p><strong>What information do we collect?</strong></p>
    <p>We collect information from you when you register on our site, subscribe to our newsletter or fill
      out a form.</p>
    <p>When ordering or registering on our site, as appropriate, you may be asked to enter your: name,
      e-mail address or phone number. You may, however, visit our site anonymously.</p>
    <p>No mobile information will be shared with third parties/affiliates for marketing/promotional
      purposes; all the above categories exclude text messaging originator opt-in data and consent;
      this information will not be shared with any third parties. Follow link for our full <a href="/sms">SMS Texting
      Policy</a>.</p>
    <p><strong>What do we use your information for?</strong></p>
    <p>Any of the information we collect from you may be used in one of the following ways:</p>
    <ul>
      <li>To personalize your experience</li>
    </ul>
    <blockquote>Your information helps us to better respond to your individual needs</blockquote>
    <ul>
      <li>To improve customer service</li>
    </ul>
    <blockquote>Your information helps us to more effectively respond to your customer service requests and support needs</blockquote>
    <ul>
      <li>To process transactions</li>
    </ul>
    <blockquote>Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.</blockquote>
    <ul>
      <li>To send periodic emails</li>
    </ul>
    <p>The email address you provide for order processing, will be used to send you information and updates pertaining to your order and emails that may include company news, updates, related product or service information, etc</p>
    <p><strong>Note</strong>: If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.</p>
    <p><strong>How do we protect your information?</strong></p>
    <p>We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. We want you to feel confident about using this website to make travel arrangements, and we are committed to protecting the information we collect. While no website can guarantee security, we have implemented appropriate administrative, technical, and physical security procedures to help protect the personal information you provide to us. For example, only authorized employees are permitted to access personal information, and they may only do so for permitted business functions. In addition, we use encryption when transmitting your sensitive personal information between your system and ours, and we employ firewalls and intrusion detection systems to help prevent unauthorized persons from gaining access to your information.</p>
    <p><strong>Do we use cookies?</strong></p>
    <p>Yes, cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information.</p>
    <p>We use cookies to understand and save your preferences for future visits and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</p>
    <p><strong>Online Advertising We Use</strong></p>
    <p>We use Google AdWords Remarketing to advertise trigger across the Internet. AdWords remarketing will display relevant ads tailored to you based on what parts of the Thrive Technologies Inc. website you have viewed by placing a cookie on your machine. <strong>THIS COOKIE DOES NOT IN ANYWAY IDENTIFY YOU OR GIVE ACCESS TO YOUR COMPUTER</strong> The cookie is used to say "This person visited this page, so show them ads relating to that page." Google AdWords Remarketing allows us to tailor our marketing to better suit your needs and only display ads that are relevant to you.</p>
    <p><strong>How to Opt Out of Remarketing and Advertising</strong> If you do not wish to participate in our Google AdWords Remarketing, you can opt out by visiting <a href="https://www.google.com/settings/ads/onweb/?sig=ACi0TCh1yimtGw_MCXmyADlHvvNP9ru5SKV9iAKbteqQbUDtboQl0SdUSbwEmoNTXTgnRO-cibkW-SaqoP_hqySYAEFc81F3x_CPdSfJh9l_RsKo6erVcaAnk4XgPtkkyHbV1Pv9yO1rb7AzNUD5rsTct1zzgNfoKIMUxeDT_4FkzCX6sP9tVEyhCzqG6ROQv5tCMf7yyMFhq2MtTRDLTch9bUH9Q9dB0jgIfyeRddAr5tNKR0-C4y20NKYzNXgSDMWONWdq4fSi&amp;hl=en">Google's Ads Preferences Manager</a></p>
    <p>You can also opt out of any third-party vendor's use of cookies by visiting <a href="http://www.networkadvertising.org/choices/">www.networkadvertising.org/choices/</a></p>
    <p><strong>Do we disclose any information to outside parties?</strong></p>
    <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>
    <p><strong>Third party links</strong></p>
    <p>Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites, and we recommend that you examine the privacy statements posted on those other websites to understand their procedures for collecting, using, and disclosing personal information.</p>
    <p><strong>California Online Privacy Protection Act Compliance</strong></p>
    <p>Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent.</p>
    <p><strong>Children’s Online Privacy Protection Act Compliance</strong></p>
    <p>We are in compliance with the requirements of COPPA (Children’s Online Privacy Protection Act), we do not collect any information from anyone under 13 years of age. Our website, products and services are all directed to people who are at least 13 years old or older.</p>
    <p><strong>Visiting our website from outside the United States</strong></p>
    <p>If you are visiting our website from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States, where our servers are located and our central database is operated. The data protection and other laws of the United States and other countries might not be as comprehensive as those in your country, but please be assured that we take steps to ensure that your privacy is protected. By using our services, you understand that your information may be transferred to our facilities and those third parties with whom we share it as described in this Policy.</p>
    <p><strong>U.S.-Swiss Safe Harbor Framework</strong></p>
    <p>As described on <a href="https://www.ftc.gov/business-guidance/resources/federal-trade-commission-enforcement-us-eu-us-swiss-safe-harbor-frameworks" target="_blank">Safe Harbor Privacy Statement</a>, we comply with the US-EU Safe Harbor Framework and the US-Swiss Safe Harbor Framework as set forth by the US Department of Commerce regarding the collection, use and retention of personal information from European Union member countries and Switzerland.</p>
    <p><strong>Online Privacy Policy Only</strong></p>
    <p>This online Privacy Policy applies only to information collected through our website and not to information collected offline.</p>
    <p><strong>Your Consent</strong></p>
    <p>By using our site, you consent to our Privacy Policy.</p>
    <p><strong>Changes to our Privacy Policy</strong></p>
    <p>If we decide to change our privacy policy, we will post those changes on this page, and/or update the Privacy Policy modification date below.</p>
    <p>This policy was last modified on 08/05/2023.</p>
    <p><strong>Contacting Us</strong></p>
    <p>For quick answers to your questions email:</p>
    <p><a href="mailto:info@thrivetechnologies.io">info@thrivetechnologies.io</a></p>
  </section>
</template>

<style scoped>
* {
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
  line-height: 2.4rem;
}

p {
  margin-top: 20px;
}

.terms-container {
  margin-top: 20px;
  margin-bottom: 50px;
}

blockquote {
  font-style: italic;
}
</style>