<script setup>
</script>

<template>
  <div class="section-form">
    <div class="main cta-box">
      <div class="card ">
        <div class="heading-secondary">
          <p>Thank you!</p>
        </div>
        <div class="body">

          <p class="heading-tertiary">Your form submission has been received.</p>

          <p>
            <a class="btn btn--full" href="/">Back to Thrive</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.section-form {
  padding: 0;
}
h1 {
  margin: 0;
  font-size: 22px;
  line-height: 24px;
}

.main {
  /* position: relative; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.cta-box {
  grid-template-columns: 1fr;
}

a {
  margin: 0;
  text-decoration: none;
  font-weight: 600;
  line-height: 24px;
  color: #007067;
}

a svg {
  position: relative;
  top: 2px;
}

a:hover,
a:focus {
  text-decoration: underline;
  text-decoration-color: #f4bb00;
}

a:hover svg path{
  fill: #007067;
}

p:last-of-type {
  margin: 0;
}

p {
  text-align: center;
}
</style>