<script setup>

</script>

<template>
  <section class="container terms-container">
    <p>Thrive Technologies <strong>SMS Privacy Policy</strong></p>
    <p>This Policy is for SMS communication purposes only and does not supersede our main Thrive
      Technologies <a href="/privacy">Privacy Policy.</a></p>
    <p>At Thrive Technologies, we value privacy and as part of this commitment, we have
      established the following Policy regarding the use of our SMS service and how we
      collect, use, and manage your phone numbers.</p>
    <p><strong>Collection of Information</strong></p>
    <p>We collect information you directly provide to us when you register and complete
      our online form “Get in touch”. This includes your first and last name, email, phone
      number, and company information.</p>
    <p><strong>Use of Information</strong></p>
    <p>Your phone number is primarily used for transactional communication regarding
      your account/services including updates and/or any changes and occasionally for
      promotional offers.</p>
    <p><strong>Opt-In Consent</strong></p>
    <p>You have the option to opt-in and receive SMS messages by checking the opt-in box
      prior to submitting the form at your time of registration. You may also opt-in at any
      time by texting <b>YES</b>, <b>START</b> or <b>SUBSCRIBE</b>.</p>
    <p><strong>Opting Out</strong></p>
    <p>If you wish to opt-out and stop receiving SMS messages from us, simply text back
      <b>NO</b>, <b>STOP</b> or <b>UNSUBSCRIBE</b>. Please allow up to 5 business days to completely
      remove your phone number from our opt-in list. You may still receive some SMS
      messages during this period. For help, please reply <b>HELP</b> for further assistance.</p>
    <p>Once you have opted out, we will no longer send any marketing or promotional SMS
      messages to you. However, we reserve the right to continue sending transactional
      SMS messages to notify you of any updates or changes related to your account.</p>
    <p><strong>Changes to Our SMS Privacy Policy</strong></p>
    <p>If we decide to change our SMS Privacy Policy, we will post those changes, and/or
      update the SMS Privacy Policy modification date below.</p>
    <p>This policy was last modified on 08/25/2023.</p>
    <p><strong>Contacting Us</strong></p>
    <p>For quick answers to your questions, email:</p>
    <p><a href="mailto:info@thrivetechnologies.io">info@thrivetechnologies.io</a></p>
  </section>
</template>

<style scoped>
* {
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
  line-height: 2.4rem;
}

p {
  margin-top: 20px;
}

.terms-container {
  margin-top: 20px;
  margin-bottom: 50px;
}
</style>