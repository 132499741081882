<template>
  <main>
    <section class="section-hero">

      <div class="hero">
        <div class="hero-text-box">
          <h1 class="heading-primary">Technology built to help you thrive</h1>

          <div class="hero-images">
            <div class="hero-icons">
              <img src="../assets/img/hero/laptop-outline.svg" class="hero-svg" alt="Hero Icon">
              <img src="../assets/img/hero/airplane-outline.svg" class="hero-svg" alt="Hero Icon">
              <img src="../assets/img/hero/bed-outline.svg" class="hero-svg" alt="Hero Icon">
              <img src="../assets/img/hero/car-outline.svg" class="hero-svg" alt="Hero Icon">
              <img src="../assets/img/hero/git-compare-outline.svg" class="hero-svg" alt="Hero Icon">
              <img src="../assets/img/hero/cloud-download-outline.svg" class="hero-svg" alt="Hero Icon">
            </div>

            <img src="../assets/img/hero.svg" class="hero-img-mobile" alt="green-sphere">

            <!-- <div class="hero-img-box-mobile">
                <img src="img/hero.svg" class="hero-img-mobile" alt="green-sphere">
            </div> -->
          </div>

          <!-- option 1 -->
          <p class="hero-description">Gain insight.</p>
          <p class="hero-description">Transform your distribution.</p>
          <p class="hero-description margin-btm-sm">Streamline your operation.</p>

          <div class="hero-start-thriving">
            <a href="#contact" class="btn btn--full margin-right-sm">Start Thriving</a>
          </div>

        </div>

        <div class="hero-img-box">
          <img src="../assets/img/hero.svg" class="hero-img" alt="green-sphere">
        </div>
      </div>

    </section>

    <section class="section-products">
      <div class="container product-header-box">
        <p><span class="subheading">Our Services</span></p>
        <h2 class="heading-secondary">Products tailored to your business</h2>
      </div>

      <div class="product-box container grid grid--2-cols grid--center-v">

        <!-- API -->
        <div class="product-text-box">
          <p class="product-name">API</p>
          <h3 class="heading-tertiary">Boosted capabilities through integration</h3>

          <!--  MOBILE  -->
          <div class="product-img-header-box">
            <img src="../assets/img/ourServices/API.svg" class="product-img-header" alt="API Product"/>
          </div>
          <!--  MOBILE  -->

          <p class="product-description">
            Get access to creative software solutions that reach beyond what is expected.
            Utilize novel technology designed to broaden your e-commerce travel options.
            Connect to expand your existing travel supply or simply plug in for the best deals on the market!
          </p>
        </div>
        <div class="product-img-box">
          <img src="../assets/img/ourServices/API.svg" class="product-img" alt="API Product"/>
        </div>

        <!-- OTA -->
        <div class="product-img-box">
          <img src="../assets/img/ourServices/OTA.svg" class="product-img" alt="OTA Product"/>
        </div>
        <div class="product-text-box">
          <p class="product-name">OTA</p>

          <h3 class="heading-tertiary">Creation without hesitation</h3>

          <!--  MOBILE  -->
          <div class="product-img-header-box">
            <img src="../assets/img/ourServices/OTA.svg" class="product-img-header" alt="OTA Product"/>
          </div>
          <!--  MOBILE  -->

          <p class="product-description">
            Whether enhancing your current website or rebranding and starting fresh, we're your ticket to an early arrival.
            Launch your own Online Travel Agency leveraging our travel booking engine to quickly start selling flights, hotels, rental cars, and more.
            No stopovers. No delays.
          </p>
        </div>

        <!-- OBT -->
        <div class="product-text-box">
          <p class="product-name">OBT</p>
          <h3 class="heading-tertiary">Your Brand &mdash; Our Platform</h3>

          <!--  MOBILE  -->
          <div class="product-img-header-box">
            <img src="../assets/img/ourServices/OBT.svg" class="product-img-header" alt="OBT Product"/>
          </div>
          <!--  MOBILE  -->

          <p class="product-description">
            Simplify business and leisure travel with our Online Booking Tool.
            Gathering, processing, and delivering information associated with Travel Management Systems can be overwhelming.
            Unify supply options, incorporating our content with yours, to mitigate the complexities of travel logistics.
            Applying our software puts you in the pilot seat of your operation.
          </p>
        </div>
        <div class="product-img-box">
          <img src="../assets/img/ourServices/OBT.svg" class="product-img" alt="OBT Product"/>
        </div>
      </div>
    </section>

    <section class="section-why">
      <div class="container center-text">
        <span class="subheading">Why Thrive?</span>
        <h2 class="heading-secondary">End-to-end solution for your unique travel needs</h2>
      </div>

      <div class="container grid grid--4-cols">
        <!-- Future -->
        <div class="why-reason">
          <img src="../assets/img/whyThrive/technology.svg" alt="Technology">
          <p class="reason-title">Technology</p>
          <p class="reason-description">
            Simplify all things travel with the right technology.
            Our intuitive software solutions are easy to use for individuals of varying industry exposure.
            Innovative development is our core focus, with a dedicated team of software engineers constantly monitoring and updating the API and Online Booking Tool at your disposal.
          </p>
        </div>
        <!-- Connect -->
        <div class="why-reason">
          <img src="../assets/img/whyThrive/connectivity.svg" alt="Connectivity">
          <p class="reason-title">Connectivity</p>
          <p class="reason-description">
            Aggregate all of your suppliers in a single source of truth.
            Our repository allows you to diversify your travel inventory with world-class fares.
            Manage your consumer content, effortlessly adjusting fare mark-ups and swapping between suppliers you wish to utilize.
          </p>
        </div>
        <!-- Support -->
        <div class="why-reason">
          <img src="../assets/img/whyThrive/support.svg" alt="Support">
          <p class="reason-title">Support</p>
          <p class="reason-description">
            From account advisor to customer service, our team is committed to help you thrive.
            We strongly believe that collaboration is crucial for growth and success.
            Our technical support has you covered, and suggestions for additional features and functionality are always welcome.
          </p>
        </div>
        <!-- Reach -->
        <div class="why-reason">
          <img src="../assets/img/whyThrive/distribution.svg" alt="Distribution">
          <p class="reason-title">Distribution</p>
          <p class="reason-description">
            The magnitude of travel services on the market can make it difficult for your content to reach customers.
            Luckily, our OTA solutions are coalesced with metadata geared at breaking through that barrier to expand your distribution.
          </p>
        </div>
      </div>
    </section>

    <section class="section-integrate">
      <div>
        <span class="section-integrate-title">Areas of Integration</span>
      </div>



      <div class="integrate-box container grid grid grid--2-cols">

        <div class="integrate-img-box" >
          <img src="../assets/img/integrate.svg" class="integrate-img" alt="Integration Image"/>
        </div>





        <!-- <div class="integrate-img-box" >
            <img src="img/SVG for anim/ball.svg" class="integrate-img" alt="Integration Image"/>
            <img src="img/SVG for anim/base.svg" class="integrate-img" alt="Integration Image"/>
            <img src="img/SVG for anim/bubbles.svg" class="integrate-img" alt="Integration Image"/>
            <img src="img/SVG for anim/light.svg" class="integrate-img" alt="Integration Image"/>
            <img src="img/SVG for anim/ring1.svg" class="integrate-img" class="integrate-img" alt="Integration Image"/>
            <img src="img/SVG for anim/ring2.svg" class="integrate-img" alt="Integration Image"/>
            <img src="img/SVG for anim/ring3.svg" class="integrate-img" alt="Integration Image"/>
        </div>  -->


        <!-- <embed src="img/SVG for anim/THRIVE-anim1.fla" width="550" height="400"></embed> -->

        <div class="integrate-text-box">
          <span class="integrate-title">Areas of Integration</span>
          <div class="integrate-list container grid grid grid--2-cols">
            <ul class="list">
              <li class="list-item">
                <img src="../assets/img/integrate-bullet.svg" class="list-icon" alt="">
                <span>TMCs</span>
              </li>
              <li class="list-item">
                <img src="../assets/img/integrate-bullet.svg" class="list-icon" alt="">
                <span>SMBs</span>
              </li>
              <li class="list-item">
                <img src="../assets/img/integrate-bullet.svg" class="list-icon" alt="">
                <span>Consortias</span>
              </li>
              <li class="list-item">
                <img src="../assets/img/integrate-bullet.svg" class="list-icon" alt="">
                <span>Independent Advisors</span>
              </li>

            </ul>
            <ul class="list margin-left-sm">
              <li class="list-item">
                <img src="../assets/img/integrate-bullet.svg" class="list-icon" alt="">
                <span>OTAs</span>
              </li>
              <li class="list-item">
                <img src="../assets/img/integrate-bullet.svg" class="list-icon" alt="">
                <span>Affinity Groups</span>
              </li>
              <li class="list-item">
                <img src="../assets/img/integrate-bullet.svg" class="list-icon" alt="">
                <span>Membership Clubs</span>
              </li>
              <li class="list-item">
                <img src="../assets/img/integrate-bullet.svg" class="list-icon" alt="">
                <span>And many more!</span>
              </li>
            </ul>
          </div>
          <h3 class="cta-text center-text">Ready to Thrive?</h3>
          <div class="join-btn">
            <a href="#contact" class="btn btn--full">Sign Up</a>
          </div>
        </div>

      </div>
    </section>

    <section class="section-form" id="contact">
      <div class="container">
        <div class="cta-box">
          <div class="form-text-box">
            <h2 class="heading-secondary center-text">Get in touch</h2>

            <!-- <form class="form-box" name="contact-form" netlify> -->
            <form class="form-box"
                  name="contact-form"
                  @submit="submitHandler"
                  data-netlify="true">

              <div>
                <label for="first-name">First Name</label>
                <input type="text" name="First Name" v-model="formData.first_name" placeholder="First Name" id="first-name" required />
              </div>

              <div>
                <label for="last-name">Last Name</label>
                <input type="text" name="Last Name" v-model="formData.last_name" placeholder="Last Name" id="last-name" required />
              </div>

              <div>
                <label for="job-title">Job Title</label>
                <input type="text" name="Job Title" v-model="formData.job_title" placeholder="Job Title" id="job-title" required />
              </div>

              <div>
                <label for="company">Company</label>
                <input type="text" name="Company" v-model="formData.company" placeholder="Company" id="company" required />
              </div>

              <div>
                <label for="email">Email</label>
                <input type="email" name="Email" v-model="formData.email" placeholder="Email" id="email" required />
              </div>

              <div>
                <label for="phone-number">Phone Number</label>
                <input type="tel" name="Phone Number" v-model="formData.phone" placeholder="Phone Number" id="phone-number" required />
              </div>

              <div>
                <label for="website-url">Website URL</label>
                <input type="url" name="Website URL" v-model="formData.website_url" placeholder="Website URL" id="website-url" required />
              </div>

              <div>
                <label for="business-type">Type of Business?</label>
                <select id="business-type" name="Type of Business" required v-model="formData.type_of_business">
                  <option value=""></option>
                  <option value="corporate">Corporate</option>
                  <option value="tmc">Travel Management Company</option>
                  <option value="b2b">B2B Online Travel Agency</option>
                  <option value="b2c">B2C Online Travel Agency</option>
                </select>
              </div>

              <div>
                <label for="annual-trips">Total Annual Trips?</label>
                <select id="annual-trips" name="Annual Travel" required v-model="formData.total_annual_trips" >
                  <option value=""></option>
                  <option value="less-5k">Under 5K</option>
                  <option value="5-15k">5K to 15K</option>
                  <option value="15-30k">15K to 30K</option>
                  <option value="over-30k">Over 30K</option>
                </select>
              </div>

              <div>
                <label for="product-interest">Product of Interest?</label>
                <select id="product-interest" name="Product of Interest" required v-model="formData.product_of_interest">
                  <option value=""></option>
                  <option value="api-interest">API</option>
                  <option value="ota-interest">OTA</option>
                  <option value="obt-interest">OBT</option>
                </select>
              </div>

              <button class="btn btn--form">
                <button-loader v-if="loading"/>
                <template v-else>
                  Submit
                </template>
              </button>
            </form>
          </div>


          <div class="form-image-box" role="img" aria-label="abstract node list"></div>
        </div>
        <div class="--disclaimer">
          <input type="checkbox" v-model="formData.sms_agreement">
          <div>
            By checking this box, you consent to receiving text messages from Thrive Technologies to the phone number you provided above.
            Consent is not a condition of purchase. Message and data rates may apply. Unsubscribe at any time by replying NO or STOP.
            <a href="/sms">SMS Privacy Policy.</a>
          </div>
        </div>
      </div>
    </section>


    <footer class="footer no-flow">
      <div class="container">
        <div class="footer-links">
          <p class="footer-title">Thrive Technologies, Inc.</p>
          <ul class="footer-list">
            <li><a href="/privacy">Privacy</a></li>
            <li><a href="/terms">Terms</a></li>
            <li>Become a Partner</li>
            <li><ion-icon name="logo-linkedin"></ion-icon></li>
            <li><ion-icon name="logo-twitter"></ion-icon></li>
            <li><a href="mailto:info@thrivetechnologies.io">info@thrivetechnologies.io</a></li>
          </ul>
        </div>
      </div>

      <div class="container">
        <p class="copyright">&copy; 2023. All Rights Reserved to Thrive Technologies, Inc.</p>
      </div>
    </footer>
  </main>
</template>

<script>
import ButtonLoader from "@/components/ButtonLoader.vue";
import axios from "axios";

export default {
  name: 'MainPage',
  components: {ButtonLoader},
  props: {
    msg: String
  },
  data() {
    return {
      loading: false,
      formData: {
        "first_name": "",
        "last_name": "",
        "job_title": "",
        "company": "",
        "email": "",
        "phone": "",
        "website_url": "",
        "type_of_business": "",
        "total_annual_trips": "",
        "product_of_interest": "",
        "sms_agreement": true,
      }
    }
  },
  methods: {
    async submitHandler(e) {
      e.preventDefault();
      e.stopPropagation();
      if (this.loading) {
        return false;
      }
      this.loading = true;
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/thrive-technologies/lead`, this.formData);
        this.$router.push('/submit');
      } catch (e) {
        alert('Something went wrong. Please try again later')
      }
      this.loading = false;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
