<template>
  <header class="header" v-if="$route.name !== 'submit'">
    <a href="/">
      <img class="logo" alt="Thrive Logo" src="/img/icon2.svg"  />
    </a>
    <nav class="main-nav">
      <ul class="main-nav-list">
        <li><a class="main-nav-link nav-cta" href="#">Log In</a></li>
      </ul>
    </nav>

    <button class="btn-mobile-nav">
      <ion-icon class="icon-mobile-nav" name="menu-outline"></ion-icon>
      <ion-icon class="icon-mobile-nav" name="close-outline"></ion-icon>
    </button>
  </header>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">
@import "src/assets/css/general";
@import "src/assets/css/style";
@import "src/assets/css/queries";
</style>
