import { createApp } from 'vue'
import App from './App.vue'
import MainPage from "@/components/MainPage.vue";
import PrivacyPage from "@/components/PrivacyPage.vue";
import TermsPage from "@/components/TermsPage.vue";
import SMSPolicy from "@/components/SMSPolicy.vue";
import SubmitPage from "@/components/SubmitPage.vue";
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    { path: '/', component: MainPage },
    { path: '/privacy', component: PrivacyPage },
    { path: '/terms', component: TermsPage },
    { path: '/sms', component: SMSPolicy },
    { path: '/submit', component: SubmitPage, name: "submit" },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

createApp(App).use(router).mount('#app')
